import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Capell en forma d’ou allargat de fins a 12 cm d’altura que es va obrint poc a poc acabant en forma de campana. Està cobert d’esquames blanques separades i formant metxes. La part apical és de color marró o groguenc. Les làmines són lliures, molt atapeïdes i fines, primer blanques, després rosades per acabar negres, deliqüescents. El peu de 10-30 x 1-2 cm, de color blanc, glabre, un poc eixamplat a la base i gairebé llis, posseeix un anell a la zona mitja de color blanc, membranós i caduc. Les espores són negres en massa, el·líptiques, de 10-15 x 6-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      